const pageHeader = document.querySelector('.js-pageheader');
const pageHeaderContent = document.querySelector('.js-pageheader-content');

if (pageHeader && pageHeaderContent) {
  const updateOffset = () => {
    document.documentElement.style.setProperty('--pageheader-content-height', pageHeaderContent.offsetHeight + 'px');
    document.documentElement.style.setProperty('--pageheader-height', pageHeader.offsetHeight + 'px');
  };

  updateOffset();

  const resizeObserver = new ResizeObserver(updateOffset);
  resizeObserver.observe(pageHeader, {box: 'border-box'}); // border-box includes padding changes
}
